
<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ Object.entries(this.data).length === 0 ? "ADD NEW" : "UPDATE" }} MENU ITEM</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">

        <vs-select
          label="Pilih Menu Item"
          v-model="dataItem"
          class="w-full"
          @input="pilihMenuItem"
          >
            <vs-select-item :key="index" :value="itemx.value" :text="itemx.text" v-for="(itemx, index) in ItemMenu"/>
        </vs-select>

        <vs-table ref="table" multiple v-model="selected" search :data="Items" v-if="showItemSelect">

          <template slot="thead">
            <vs-th>{{dataItem}}</vs-th>
          </template>

          <template slot-scope="{data}">
            <tbody>
              <vs-tr :data="tr" :key="index" v-for="(tr, index) in data">

                <vs-td>
                  <p v-if="dataItem === 'kategori'">{{ tr.name }}</p>
                  <p v-else>{{ tr.post_title }}</p>
                </vs-td>

              </vs-tr>
            </tbody>
          </template>
        </vs-table>

        <div v-if="showItemLink">
          <vs-input label="Label" v-model="dataMenuLabel" class="mt-5 w-full" name="item-menu" v-validate="'required'" />
          <vs-input label="Link" v-model="dataMenuLink" class="mt-5 w-full" name="item-menu" v-validate="'required'" />
        </div>

      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Add Menu</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapGetters,mapActions } from 'vuex'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {
      selected: [],
      dataItem: null,
      ItemMenu: [
        {
          value: "custom",
          text: "Custom Link",
        },
        {
          value: "kategori",
          text: "Kategori",
        },
        {
          value: "pages",
          text: "Pages",
        },
        {
          value: "post",
          text: "Post",
        },
      ],
      showItemSelect : false,
      showItemLink : false,
      Items: [],
      linkItem : null,
      titleItem : null,

      dataId: null,
      dataMenu: null,

      dataMenuLink: null,
      dataMenuLabel: null,

      settings: {
        maxScrollbarLength: 80,
        wheelSpeed: .80
      }
    }
  },
  watch: {
    isSidebarActive (val) {
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        const { id, name } = JSON.parse(JSON.stringify(this.data))
        this.dataId = id
        this.dataMenu = name
        this.initValues()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
          this.$validator.reset()
          this.initValues()
        }
      }
    },
    isFormValid () {
      return this.selected.length > 0 || this.dataMenuLink
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag },
    ...mapGetters({
      user : 'auth/user',
    }),
  },
  methods: {
    ...mapActions({
      setAuth   : 'auth/set',
    }),
    initValues () {
      if (this.data.id) return
      this.dataId = null
      this.dataMenu = null
    },
    pilihMenuItem () {
      if (this.dataItem == 'pages') {
        this.linkItem = '/post-all/pages'
      } else if (this.dataItem == 'post') {
        this.linkItem = '/post-all/post'
      } else if (this.dataItem == 'kategori') {
        this.linkItem = '/term-list/kategori'
      } 
      if (this.dataItem != 'custom') {
        let configs = {
          headers: {
            'Authorization': 'Bearer ' + this.user.api_token,
          },
        }
        this.axios.get(this.linkItem, configs)
        .then((response) => {
          this.Items = []
          let {data} = response.data
          this.Items = data
        })
        .catch((error) => {
          let data = error.data
          this.Items = data
        })
        this.showItemSelect = true
        this.showItemLink = false
      } else {
        this.showItemLink = true
        this.showItemSelect = false
      }
    },
    submitData () {
      if (this.selected.length > 0) {
        let dataType = null
        if (this.dataItem == 'kategori') {
          dataType = 'taxonomy'
        } else {
          dataType = 'post_type'
        }
        const obj = {
          data_menu: this.selected,
          data_object: this.dataItem,
          data_type: dataType,
          nav_menu: this.$route.params.slug_nav_menu
        }
        this.$store.dispatch('menuitem/addMenuitem', obj)
        this.selected = []
      } else {
        const obj = {
          data_link: this.dataMenuLink,
          data_label: this.dataMenuLabel,
          data_object: this.dataItem,
          data_type: 'custom',
          nav_menu: this.$route.params.slug_nav_menu
        }
        this.$store.dispatch('menuitem/addMenuitem', obj)
        this.dataMenuLink = null
        this.dataMenuLabel = null
        this.dataItem = null
      }
      this.$emit('closeSidebar')
      //this.showItemSelect = false
      //this.showItemLink = false
      //this.initValues()
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 450px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

    &:not(.ps) {
      overflow-y: auto;
    }
}
</style>
