
<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarEditLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ Object.entries(this.data).length === 0 ? "ADD NEW" : "UPDATE" }} MENU ITEM</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarEditLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">

        <vs-input label="Order" v-model="dataMenuorder" class="mt-5 w-full" name="menu_order" v-validate="'required'" />

        <vs-input label="Title" v-model="dataMenutitle" class="w-full" name="menu_title" v-validate="'required'" />

        <vs-input label="Type" v-model="dataMenutype" class="mt-5 w-full" name="menu_type" v-validate="'required'" />

        <vs-input label="Object" v-model="dataMenuobject" class="mt-5 w-full" name="menu_object" v-validate="'required'" />

        <vs-input label="URL" v-model="dataMenuurl" class="mt-5 w-full" name="menu_url" v-validate="'required'" />

        <vs-select v-model="dataMenustatus" label="Status" class="mt-5 w-full" name="menu_status" >
          <vs-select-item value="true" text="Aktif" />
          <vs-select-item value="false" text="Tidak" />
        </vs-select>

        <div class="mt-5"></div>
        <label class="vs-input--label">Header</label>
        <div class="">
            <vs-radio v-model="dataMenuheader" vs-name="header" vs-value="true" class="mr-4">TRUE</vs-radio>
            <vs-radio v-model="dataMenuheader" vs-name="header" vs-value="false">FALSE</vs-radio>
        </div>

        <vs-select v-model="dataMenuparent" label="Parent" class="mt-5 w-full" name="menu_parent" >
          <vs-select-item value="0" text="Tidak ada" />
          <vs-select-item :key="item.id" :value="item.id" :text="item.menu_title" v-for="item in parentMenu" />
        </vs-select>

        <vs-select v-model="dataMenugroup" label="Group" class="mt-5 w-full" name="menu_group" >
          <vs-select-item value="0" text="Tidak ada" />
          <vs-select-item :key="item.id" :value="item.id" :text="item.menu_title" v-for="item in parentMenu" />
        </vs-select>

        <vs-input label="Icon" v-model="dataMenuicon" class="mt-5 w-full" name="menu_icon" />

        <vs-input label="Position" v-model="dataMenuposition" class="mt-5 w-full" name="menu_position" />

        <vs-input label="Target" v-model="dataMenutarget" class="mt-5 w-full" name="menu_target" />

        <vs-input label="Classes" v-model="dataMenuclasses" class="mt-5 w-full" name="menu_classes" />

        <vs-input label="XFN" v-model="dataMenuxfn" class="mt-5 w-full" name="menu_xfn" />

      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValidEdit">Submit</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarEditLocal = false">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapGetters,mapActions } from 'vuex'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {
      dataId: null,
      dataMenutitle : null, 
      dataMenuslug : null, 
      dataMenugroup : null, 
      dataMenutype : null, 
      dataMenuorder : null, 
      dataMenuheader : false, 
      dataMenustatus : true, 
      dataMenuparent : 0, 
      dataMenuposition : null, 
      dataMenuobject : null, 
      dataMenuobject_id : null, 
      dataMenutarget : null, 
      dataMenuclasses : null, 
      dataMenuxfn : null, 
      dataMenuurl : null,
      dataMenuicon : null,

      parentMenu: [],

      settings: {
        maxScrollbarLength: 80,
        wheelSpeed: .80
      }
    }
  },
  watch: {
    isSidebarActive (val) {
      let configs = {
        headers: {
          'Authorization': 'Bearer ' + this.user.api_token,
        },
      }
      let nav = this.$route.params.slug_nav_menu
      this.axios.get(`/menuitem-list/${nav}`, configs)
      .then((response) => {
        let {data} = response.data
        this.parentMenu = data
      })
      .catch((error) => {
        let data = error.data
        this.parentMenu = data
      })
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        const { id, menu_title, menu_slug, menu_group, menu_type, menu_order, menu_header, menu_status, menu_parent, menu_position, menu_object, menu_object_id, menu_target, menu_classes, menu_xfn, menu_url, menu_icon } = JSON.parse(JSON.stringify(this.data))
        this.dataId = id
        this.dataMenutitle = menu_title
        this.dataMenuslug = menu_slug
        this.dataMenugroup = menu_group
        this.dataMenutype = menu_type
        this.dataMenuorder = menu_order
        this.dataMenuheader = menu_header
        this.dataMenustatus = menu_status
        this.dataMenuparent = menu_parent
        this.dataMenuposition = menu_position
        this.dataMenuobject = menu_object
        this.dataMenuobject_id = menu_object_id
        this.dataMenutarget = menu_target
        this.dataMenuclasses = menu_classes
        this.dataMenuxfn = menu_xfn
        this.dataMenuurl = menu_url
        this.dataMenuicon = menu_icon
        this.initValues()
      }
    }
  },
  computed: {
    isSidebarEditLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
          //this.$validator.reset()
          //this.initValues()
        }
      }
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag },
    ...mapGetters({
      user : 'auth/user',
    }),
    isFormValidEdit () {
      return !this.errors.any() && this.dataId && this.dataMenutitle
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      setAuth   : 'auth/set',
    }),
    initValues () {
      if (this.data.id) return
      this.dataId = null
      this.dataMenutitle = null
      this.dataMenuslug = null
      this.dataMenugroup = null
      this.dataMenutype = null
      this.dataMenuorder = null
      this.dataMenuheader = false
      this.dataMenustatus = true
      this.dataMenuparent = 0
      this.dataMenuposition = null
      this.dataMenuobject = null
      this.dataMenuobject_id = null
      this.dataMenutarget = null
      this.dataMenuclasses = null
      this.dataMenuxfn = null
      this.dataMenuurl = null
      this.dataMenuicon = null
    },
    submitData () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const obj = {
            id: this.dataId,
            menu_title: this.dataMenutitle,
            menu_slug: this.dataMenuslug,
            menu_group: this.dataMenugroup,
            menu_type: this.dataMenutype,
            menu_order: this.dataMenuorder,
            menu_header: this.dataMenuheader,
            menu_status: this.dataMenustatus,
            menu_parent: this.dataMenuparent,
            menu_position: this.dataMenuposition,
            menu_object: this.dataMenuobject,
            menu_object_id: this.dataMenuobject_id,
            menu_target: this.dataMenutarget,
            menu_classes: this.dataMenuclasses,
            menu_xfn: this.dataMenuxfn,
            menu_url: this.dataMenuurl,
            menu_icon: this.dataMenuicon,
          }
          this.$store.dispatch('menuitem/updateMenuitem', obj)
          this.$emit('closeSidebar')
          this.initValues()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 450px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

    &:not(.ps) {
      overflow-y: auto;
    }
}
</style>
